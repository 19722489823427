<template>
    <div class='login-up'>
        <el-form class="form-content" ref="form" :model="form" label-width="55px" :rules="rules">
            <div class="project-title">石家庄市运宇物流有限公司</div>
            <div class="project-title">查单系统</div>
            <el-form-item label="账号" prop="name">
                <el-input v-model="form.name" placeholder="请输入账号" @keyup.enter.native="onSubmit"/>
            </el-form-item>
            <el-form-item label="密码" prop="pwd">
                <el-input v-model="form.pwd" placeholder="请输入密码" show-password @keyup.enter.native="onSubmit"/>
            </el-form-item>
            <el-button type="primary" @click="onSubmit" :loading="loading">登陆</el-button>
        </el-form>
        <a class="login-foot" href="https://beian.miit.gov.cn/" target="_blank">冀ICP备2021008205号-1</a>
    </div>
</template>

<script>
    import axiosUtils from "@/utils/axiosUtils";
    // import myUtils from "@/utils/myUtils";

    export default {
        name: "login",
        data() {
            return {
                loading: false,
                form: {
                    name: '',
                    pwd: '',
                },
                rules: {
                    name: [
                        {required: true, message: '账号不能为空', trigger: 'blur'},
                    ],
                    pwd: [
                        {required: true, message: '密码不能为空', trigger: 'blur'},
                    ],
                }
            }
        },
        mounted() {
            const path = location.origin
            if (path !== 'https://yywl.yunyuwuliu.com') {
                location.href = 'https://yywl.yunyuwuliu.com'
            } else {
                let res = sessionStorage.getItem('res')
                if (res) {
                    this.$router.push('/taskList');
                }
            }
        },
        methods: {
            onSubmit: function () {
                this.loading = true
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        axiosUtils.post('/loginWeb', null, this.form).then(res => {
                            this.loading = false
                            sessionStorage.setItem('user', JSON.stringify(res));
                            this.$router.push('/taskList');
                        }, () => {
                            this.loading = false
                        })
                    } else {
                        this.loading = false
                    }
                })
            },
        }
    }
</script>

<style scoped>
    .login-up {
        background: linear-gradient(#00706b, white);
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .logo {
        width: 150px;
        padding: 10px;
    }

    .content {
        width: 400px;
        text-align: center;
    }

    .project-title {
        font-weight: bold;
        font-size: 30px;
        padding-bottom: 15px;
        color: #00706b;
    }

    .form-content {
        width: 360px;
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        text-align: center;
    }

    button {
        width: 100%;
    }

    .login-foot {
        color: #00706b;
        position: absolute;
        bottom: 10px;
        text-decoration: none;
    }

    a {
        color: #00706b;
    }

    .get-in {
        position: absolute;
        top: 0;
        right: 0;
        height: 40px;
        width: 40px;
    }
</style>